@import '../../sass/variables';
@import '../../sass/mixins';
.home-page {
  &__challenges {
    background-color: $color-yellow;
    padding: 2rem 1rem;
    border-radius: 0% 0% 32% 32% / 0% 0% 3% 3%;
  }
  &__add-challenge {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
  }

  &__wrapper {
    @include wrapper($content-width-large);
  }
}
