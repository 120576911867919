@use 'reset';
@import 'variables';

body {
  margin: 0;
  background-color: $color-white;
}

html {
  scroll-behavior: smooth;
}
