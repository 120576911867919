@import '../../sass/variables';
@import '../../sass/mixins';

.day-box {
  font-size: 1rem;

  width: 2.5rem;
  height: 2.5rem;

  margin: 0.5rem;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: $color-black-light-1;
  background-color: $color-white;
  box-shadow: 0 0 0 2px $color-black-light-1, 1px 1px 0 0 $color-secondary;
  border-radius: 2px;

  &.next {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 0 2px $color-black-light-1, 2px 2px 0 0 $color-secondary;
      background-color: $color-tertiary;
      font-weight: bold;
    }
  }

  &.current {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: rgba($color-secondary, 1);
    color: $color-white;
    font-size: 1.2rem;
    font-weight: bold;
    &:hover {
      background-color: rgba($color-red, 1);
      color: $color-white;
      font-weight: bold;
    }
    &:active {
      background-color: rgba($color-black, 1);
      color: $color-white;
      font-weight: bold;
    }
  }

  &.completed {
    background-color: rgba($color-secondary, 1);
    color: $color-white;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

// Remove hover effect on mobile

@media screen and (max-width: 767px) {
  .day-box {
    &.next {
      &:hover {
        transform: scale(1);
        box-shadow: 0 0 0 2px $color-black-light-1, 1px 1px 0 0 $color-secondary;
        background-color: $color-white;
        font-weight: normal;
      }
    }
    &.current {
      &:hover {
        background-color: rgba($color-secondary, 1);
        color: $color-white;
        font-weight: bold;
      }
    }
  }
}
