//$font-stack-primary: 'Cairo', sans-serif;
//$font-stack-secondary: 'Happy Monkey', cursive;

$font-size-base: 1rem;

$color-primary: #171c2b;
$color-secondary: #7768ae;
$color-tertiary: #84dd63;

$color-red: #fe4a49;
$color-yellow: #ffbf00;

$color-background: #171c2b;

$color-black: #040404;
$color-white: #ffffff;
$color-white-light-1: #f5f5f5;

$color-grey-light-1: #e1e1e1;
$color-grey-dark-1: #adadad;

$color-black-light-1: #1a1a1a;
$color-black-light-2: #5d5d5d;

$content-width-large: 1500px;
$content-width-medium: 1200px;
