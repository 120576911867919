@import '../../sass/variables';
@import '../../sass/mixins';

.button {
  margin: 0 0.5rem;
  padding: 0.2rem 0.7rem;
  font-weight: 700;

  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: $color-secondary;
  color: $color-white;
  font-weight: 700;

  font-size: 0.9rem;
  box-shadow: 0 0 0 2px $color-black-light-1, 3px 3px 0 0 $color-black-light-1;

  &:hover {
    color: $color-secondary;
    background-color: $color-white;
    // box-shadow: 0 0 0 2px $color-black-light-1, 3px 3px 0 0 $color-black-light-1;
  }

  &--large {
    padding: 0.6rem 1.4rem;
    font-size: 1.2rem;
  }

  &--add {
    background-color: $color-secondary;
    color: $color-white;
    text-transform: uppercase;

    &:hover {
      color: $color-black-light-1;
      background-color: $color-white;
      box-shadow: 0 0 0 2px $color-black-light-1, 3px 3px 0 0 $color-secondary;
    }
  }

  &--edit {
    background-color: $color-black-light-2;
    color: $color-white;

    &:hover {
      color: $color-black-light-1;
      background-color: $color-white;
    }
  }

  &--delete {
    background-color: $color-red;
    color: $color-white;

    &:hover {
      color: $color-red;
      background-color: $color-white;
    }
  }
  &--reset {
    background-color: $color-black-light-2;
    color: $color-white;

    &:hover {
      color: $color-black-light-2;
      background-color: $color-white;
    }
  }
}
