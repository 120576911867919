@mixin shine-animation($firstColor, $secondColor) {
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, $firstColor, $secondColor);
  animation: shine 4s linear infinite;
}

@keyframes shine {
  to {
    background-position: 300% center;
  }
}

@mixin wrapper($size) {
  max-width: $size;
  margin: 0 auto;
  padding: 2rem;
}
