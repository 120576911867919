@import '../../sass/variables';
@import '../../sass/mixins';

.about-section {
  background-color: $color-white;
  padding: 8rem 0 0 0;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  p {
    padding: 0 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    width: fit-content;
  }
  span {
    font-size: 1.2rem;
    font-weight: 700;
    position: relative;
    text-wrap: nowrap;
    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 5px;
      transform: skew(-12deg);
      background: rgba($color-yellow, 0.7);
    }
  }

  &__image-wrapper {
    // I use float to make the image responsive, could be improved with flexbox
    float: right;

    max-width: 300px;
    margin: 1rem 1rem;
  }
  &__image {
    float: right;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
@media screen and (min-width: 768px) {
  .about-section {
    &__title {
      font-size: 2.2rem;
    }
  }
}
