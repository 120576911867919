@import '../../sass/variables';
@import '../../sass/mixins';

.faq {
  padding: 8rem 0 4rem 0;

  margin: 0 auto;
  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
}

// Media queries

@media screen and (min-width: 768px) {
  .faq {
    &__title {
      font-size: 2.2rem;
    }
  }
}
