@import '../../sass/variables';

.modal-edit {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color-secondary, 0.5);
  backdrop-filter: blur(2px);

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    background-color: $color-white;
    margin: 0 auto 1rem auto;
    padding: 2rem 2rem 1rem 2rem;

    width: 95%;
    box-shadow: 0 0 0 2px $color-black-light-1, 4px 4px 0 0 $color-black-light-1;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 1rem;
  }
  &__label {
    font-size: 0.8rem;
    font-weight: bold;
  }
  &__input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.3rem;
    margin-bottom: 1rem;

    box-shadow: 0 0 0 2px $color-black-light-1, 4px 4px 0 0 $color-black-light-1;

    font-size: 1rem;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $color-secondary, 4px 4px 0 0 $color-secondary;
    }
  }
  &__button {
    padding: 0.5rem 2rem;
    margin: 1rem 0;
    cursor: pointer;

    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $color-primary;
    color: $color-white;
    box-shadow: 0 0 0 0px $color-secondary, 4px 4px 0 0 $color-tertiary;
    transition: all 0.2s ease-in-out;
  }
  &__button:hover {
    background-color: $color-grey-light-1;
    color: $color-primary;
    box-shadow: 0 0 0 2px $color-black-light-1, 4px 4px 0 0 $color-black-light-1;
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.1rem;
    margin: 0 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    color: $color-black-light-2;
  }
  &__close-button:hover {
    color: $color-red;
    background-color: transparent;
  }
}

// Tablet and desktop

@media (min-width: 630px) {
  .modal-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;

    &__content {
      position: relative;
      background-color: $color-white;
      margin: 0 auto;

      width: 70%;
      padding: 2rem 2rem 1rem 2rem;
    }
    &__title {
      font-size: 2rem;
    }
  }
}
