@import '../../sass/variables';

.app-footer {
  padding: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
  border-top: $color-grey-dark-1 1px solid;

  width: 100%;
  height: 2.5rem;
  &__link {
    color: $color-secondary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: $color-secondary;
    }
  }
  &__text {
    margin: 0;
    padding: 1rem;
  }
}
