@import '../../sass/variables';
@import '../../sass/mixins';

.challenge-container {
  @include wrapper($content-width-medium);
}
.challenge-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 1.5rem;
  margin-bottom: 2rem;
  background-color: rgba($color-white, 0.9);

  box-shadow: 0 0 0 2px $color-black-light-1, 4px 4px 0 0 $color-black-light-1;
  position: relative;

  &__top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  &__today-completed {
    position: relative;
    z-index: 101;

    &::before {
      content: 'DONE';
      position: absolute;
      top: -2.5rem; /* Adjust the distance from the top */
      right: -2.2rem; /* Adjust the distance from the right */
      background-color: $color-tertiary; /* Color of the label */
      color: $color-black-light-1; //* Text color */
      font-size: 0.8rem;
      font-weight: 700;
      border: 2px solid $color-black-light-1;
      border-radius: 0.5rem;
      padding: 0.3rem 1rem;
      transition: all 0.4s ease-in-out;
    }
  }
  &__today-todo {
    position: relative;
    z-index: 101; /* Ensure the content is above the label */

    &::before {
      content: 'TO DO';
      white-space: nowrap;
      position: absolute;
      top: -1.5rem; /* Adjust the distance from the top */
      right: -2.5rem; /* Adjust the distance from the right */
      background-color: $color-primary; /* Color of the label */
      border: 2px solid $color-black-light-1;
      box-shadow: 0 0 0 2px $color-tertiary, 2px 2px 0 0 $color-tertiary;
      color: $color-white; //* Text color */
      font-size: 0.8rem;
      font-weight: 700;
      border-radius: 0.5rem;
      padding: 0.3rem 1rem;
      transform: rotate(+40deg); /* Rotate the label */
      transition: all 0.2s ease-in-out;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    &--completed {
      font-size: 1rem;
    }
  }

  &__description {
    font-size: 1.2rem;
    line-height: 1.2;
    &--completed {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  &__buttons-container {
    margin: 1rem 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    &--completed {
      justify-content: center;
      margin-top: 1.5rem;

      // I override the button styles here
      .button {
        font-size: 1rem;
        margin: 0 0.7rem;
      }
    }
  }
}

.days-container {
  display: grid;
  margin: 1rem 0;
  grid-template-columns: repeat(auto-fit, minmax(3.3rem, 1fr));
  flex-wrap: wrap;
  width: 100%;

  &.small-length {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
  }

  &.completed {
    filter: grayscale(30%) blur(5px);
    margin-top: 1.5rem;
    max-height: 150px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
}

// media queries for mobile

@media only screen and (max-width: 600px) {
  .challenge-container {
    padding: 0.5rem;
  }
  .challenge-block {
    &__title {
      font-size: 1.8rem;
    }
    &__description {
      font-size: 1rem;
    }
  }

  .days-container {
    min-height: 230px;
  }
}
