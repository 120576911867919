@import '../../sass/variables';
@import '../../sass/mixins';

.app-header {
  padding: 1.5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: $color-white;
  box-shadow: 0 0 0 2px $color-black-light-1, 3px 3px 0 0 $color-black-light-1;
  position: sticky;

  &__container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    max-width: 100rem;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;

    @include shine-animation($color-primary, $color-secondary);
  }
  span {
    font-size: 1rem;

    -webkit-text-fill-color: $color-primary;
  }
  &__nav {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;

    &-item {
      list-style: none;
      color: $color-primary;
      font-size: 1.2rem;
      font-weight: bold;
      margin-left: 1rem;
      cursor: pointer;

      transition: color 0.15s ease, border-color 0.15s ease;
    }
    a {
      position: relative; /* Add relative positioning to the anchor tags */

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 0;
        height: 3px;
        background: $color-secondary;
        transition: all 0.3s ease-in-out;
      }

      &:visited {
        color: $color-black;
      }

      &:hover {
        color: $color-black;
        font-weight: bold;

        &::after {
          width: 100%;
          left: 0;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .app-header {
    padding: 1rem 2rem 1.2rem 2rem;
    &__title {
      font-size: 1.8rem;
    }
  }
}
